:root {
	--primary-color: #F25F5C;
  --grey: #F5F5F5;
  --font-family: "Open Sans";
}

.page-heading-text{
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
}

.heading-text{
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;
}
.input-container{
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 5px #E7E7E7;
}
.input-heading{
  color: #666666;
}

.navigate-auth-text{
  font-size: 14px;
  font-weight: 600;
}

.settings-container{
  background: #F7F7F7;
  border: 1px solid #E7E7E7;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 60px 40px;
}

.invester-settings-label{
  font-weight: 600;
  font-size: 16px;
}

/* Side Modal CSS */
.side-modal-key-heading{
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  text-align: left;
  white-space: pre-wrap;
  width: 160px;
  padding-right: 10px;
  padding-left: 20px;
  vertical-align: top;
  color: #272727;
}
.side-modal-value{
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family);
  color: #272727;;
  padding-right: 10px;
}

.disabled-cursor:hover{
  cursor: not-allowed;
}